@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/inter/Inter-Regular.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("./fonts/inter/Inter-Medium.ttf");
  font-style: medium;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("./fonts/inter/Inter-Bold.ttf");
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("./fonts/inter/Inter-Black.ttf");
  font-style: medium;
  font-weight: 900;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("./fonts/inter/Inter-Light-BETA.ttf");
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

